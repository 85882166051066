/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button, Modal, Badge, Row, Col, Dropdown, ButtonGroup } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import DatePicker from 'react-datepicker';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import './ApartmentMaterialSelectionQuoteModal.scss';
import { Trash, Edit, Close, Check } from '../Icon/Icon.js';
import moment from 'moment';

class ApartmentMaterialselectionQuoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      selections: [],
      loading: true,
      error: false,
      additionalInfos: [],
      newComment: '',
      newComments: {},
      key: Math.random(),
      newQuoteComment: '',
      additionalTasks: '',
      additionalTasksPrice: null,
      selectedFiles: [],
      maxImages: 100,
      deadline: null,
      additionalRow: '',
      additionalRowPrice: null,
      additionalRowLaborPrice: null,
      additionalRows: [],
      persons: [],
      person_edited: {
        id: null,
        title: "",
        name: "",
        phone: "",
      },
      currentlyEditedRowId: null,
      openComments: [],
      openAdditionalInfo: [],
      showAdditionalRowAdd: false,
      additionalQuoteInfo: '',
      completionDate: '',
      selectionRowEdited: null,
      groupDropdown: [],
      productDropdown: [],
      products: [],
      filteredProducts: [],
      productSearch: '',
      groups: [],
      removableRows: [],
      lastSelectionId: null,
      additionalProductSelections: [],
      additionalProducts: [],
      filteredAdditionalProducts: [],
      newRows: [],
    };
  }

  componentDidMount() {
    const { id, apartment_number, stairwell } = this.props.apartment;
    const api = new AvainiaCore(LocalStorageService.getToken);
    const hasQuote = !!(this.props.quote && this.props.quote.id);
    const projectId = this.props.project.id;
    const quoteName = `Materiaalinvalintalomake asunto ${apartment_number}${stairwell}`;

    const promises = [
      !hasQuote && api.materialSelectionsGet(this.props.project.id, id, 'selections'),
      hasQuote && api.materialQuoteRowsGet(this.props.quote.id, 'materialQuoteRows'),
      hasQuote && api.materialQuoteCommentsGet(this.props.quote.id, 'materialQuoteComments'),
      hasQuote && api.materialQuotePhotosGet(projectId, this.props.quote.id, 'materialQuotePhotos'),
      hasQuote && api.materialQuoteAdditionalRowsGet(this.props.quote.id, 'additionalRows'),
      api.additionalProductsGet('additionalProducts'),
      api.productCategoriesGet('productCategories'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let selections = null;
      let materialQuoteRows = null;
      let materialQuoteComments;
      let materialQuotePhotos;
      let additionalRows;
      let productCategories;
      let products = [];
      let groups;
      let lastSelectionId = null;
      let additionalProductSelections = this.props.submissionForm.additional_product_selections;
      let additionalProducts = [];

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.selections) { selections = x.selections; }
        if (x.materialQuoteRows) { materialQuoteRows = x.materialQuoteRows; }
        if (x.materialQuoteComments) { materialQuoteComments = x.materialQuoteComments; }
        if (x.materialQuotePhotos) { materialQuotePhotos = x.materialQuotePhotos; }
        if (x.additionalRows) { additionalRows = x.additionalRows; }
        if (x.productCategories) { productCategories = x.productCategories; }
        if (x.additionalProducts) { additionalProducts = x.additionalProducts; }
      });

      if (error) { return this.setState({ error }); }

      const selectionIds = [];
      // eslint-disable-next-line no-unused-expressions
      this.props.submissionForm.material_selections && this.props.submissionForm.material_selections.forEach((element) => {
        const elementId = element.id;
        selectionIds.push({ selection_id: elementId, additional_info: '' });
      });

      // Get every product
      if (productCategories) {
        const productPromises = productCategories.map(category => api.productsGet(category.id));
        Promise.all(productPromises).then((data) => {
          data.forEach((x) => {
            if (error) { return; }
            if (x.error) { error = x.error; return; }
          });

          if (error) { return this.setState({ error }); }

          data.forEach((productArr) => {
            productArr.forEach((product) => products.push(product))
          })
        });
      }

      let rows = selections ?? materialQuoteRows;
      if (rows) {
        if (!selections) {
          // This is needed when making new quote rows
          lastSelectionId = rows[rows.length -1]?.id ? (rows[rows.length -1]?.id + 1) : 0;
        } else {
          // TODO: fix materialSelectionsGet api end point to return only selections beloging to the submission form, this is just a hotfix
          rows = rows.filter(row => row.material_form_submission_id === this.props.submissionForm.id);
        }

        const groupData = rows.map((row) => {
          // A different data structure is a hassle
          return selections ?  { name: row?.group?.name, id: row?.group?.id } : { name: row?.group_name, id: row?.group_id };
        });

        // Get unique groups with this lovely one-liner and map them in to objects nested in a array for easier iteration
        groups = [...new Map(groupData.map(item => [item['id'], item])).values()].map(group => {
          return ({
            name: group.name,
            id: group.id,
            selections: [],
          })
        });

        if (!groups.find(group => group.name === 'Lisätuotteet')) {
          groups.push({
            name: 'Lisätuotteet',
            id: null,
            selections: [],
          });
        }

        // Push material quote selections under group names
        rows.forEach(row => {
          groups.forEach((group, groupIndex) => {
            if ((selections ? row.group?.name : row.group_name) === group.name) {
              groups[groupIndex].selections.push(row);
            }
          })
        });
      }

      this.setState({
        deadline: (this.props.quote && this.props.quote.deadline !== '0000-00-00 00:00:00') ? new Date(this.props.quote.deadline) : null,
        selections,
        name: (this.props.quote && this.props.quote.name) ? this.props.quote.name : quoteName,
        materialQuoteComments,
        loading: false,
        additionalInfos: {
          infos: this.props.submissionForm.material_quote && this.props.submissionForm.material_quote.rows ? this.props.submissionForm.material_quote.rows : selectionIds,
        },
        additionalTasks: this.props.quote ? this.props.quote.other_tasks : null,
        additionalTasksPrice: this.props.quote ? this.props.quote.other_tasks_price : null,
        selectedFiles: materialQuotePhotos,
        additionalRows,
        persons: (this.props.quote && this.props.quote.persons) ? this.props.quote.persons : [],
        additionalQuoteInfo: this.props?.quote?.additional_info ?? '',
        completionDate: this.props?.quote?.completion_date ?? '',
        products,
        filteredProducts: products,
        groups,
        lastSelectionId,
        additionalProductSelections,
        additionalProducts,
        filteredAdditionalProducts: additionalProducts,
      });
    });
  }

  onChangeComments = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const { additionalInfos } = this.state;

    const updateThisInfoRow = additionalInfos.infos.find((row) => row.id == name);
    updateThisInfoRow.additional_info = value;

    const updatedInfos = [...additionalInfos.infos];

    this.setState({
      ...this.state,
      additionalInfos: {
        ...additionalInfos,
        infos: updatedInfos,
      },
    });
  }

  getQuoteRowsCallback = async (quote) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    quote = quote ?? this.props.quote;
    const promises = [api.materialQuoteRowsGet(quote.id, 'materialQuoteRows')];

    Promise.all(promises).then((data) => {
      let error = false;
      let materialQuoteRows = null;
      let groups;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.materialQuoteRows) { materialQuoteRows = x.materialQuoteRows; }
      });

      if (error) { return this.setState({ error }); }

      const groupData = materialQuoteRows.map((row) => {
        // A different data structure is a hassle
        return { name: row.group_name, id: row.group_id };
      });

      // Get unique groups with this non-DRY one-liner and map them in to objects nested in a array for easier iteration
      groups = [...new Map(groupData.map(item => [item['id'], item])).values()].map(group => {
        return ({
          name: group.name,
          id: group.id,
          selections: [],
        })
      });

      if (!groups.find(group => group.name === 'Lisätuotteet')) {
        groups.push({
          name: 'Lisätuotteet',
          id: null,
          selections: [],
        });
      }

      // Push material quote selections under group names
      materialQuoteRows.forEach(row => {
        groups.forEach((group, groupIndex) => {
          if ((row.group_name) === group.name) {
            groups[groupIndex].selections.push(row);
          }
        })
      });

      this.setState({ groups}, () => {
        if (this.state.loading) { // this callback might break something
          this.setState({ loading: false });
        }
      });
    })
  }

  createQuote = () => {
    if (this.state.loading) { return; }
    this.setState({loading: true}); // Looks a bit weird but this might make sense

    const projectId = this.props.project.id;
    const apartmentId = this.props.apartment.id;
    const submissionId = this.props.submissionForm.id;
    const formattedDate = this.state.deadline ? new Date(this.state.deadline.getTime() - (this.state.deadline.getTimezoneOffset() * 60000)) : '';

    if(apartmentId != this.props.submissionForm.apartment_id){
      alert("Virhe tarjousta luodessa, päivitä sivu ja yritä uudelleen.");
      return false;
    }

    const payload = {
      apartment_id: apartmentId,
      material_form_submission_id: submissionId,
      name: this.state.name,
      rows: [],
      deadline: formattedDate,
      is_due: !!this.state.deadline,
      additional_tasks: this.state.additionalTasks,
      additional_tasks_price: this.state.additionalTasksPrice || 0,
    };

    this.state.groups.forEach((group) => {
      group.selections.forEach((selection) => {
        const info = Object.values(this.state.additionalInfos.infos).find((x) => { if (x.selection_id == selection.id) { return x.selection_id == selection.id; } });
        let groupname = group ? group.name : 'null';
        
        payload.rows.push({
          id: selection.id,
          description: `${groupname}, ${selection.select.name}, ${selection.name}`, // TODO: check if this is used anywhere
          price: selection.price,
          labor_price: selection.material_form_option.labor_price,
          package_amount: selection.material_form_option.package_amount,
          comment: selection.comment,
          pid: selection.product_id,
          additional_info: info ? info.additional_info : '',
          selection_id: selection.id, // = material selection id
          selection_type: 'material_selection', // = morphable type
          group_id: group.id,
          source: 'owner',
          selection_name: selection.select.name,
          product_name: selection.product.name,
          product_measurements: selection.product.measurements,
          accepted_by_management: true,
          accepted_by_owner: true,
        });
      })
    });

    this.state.additionalProductSelections.forEach((product) => {
      const targetProduct = this.props.submissionForm.additional_products.find(target => target.id === product.additional_product_id);
      payload.rows.push({
        accepted_by_management: true,
        accepted_by_owner: true,
        additional_info: null,
        comment: product.comment,
        description: targetProduct.description ?? '',
        group_id: null,
        labor_price: targetProduct.labor_price,
        package_amount: null,
        pid: targetProduct.id,
        price: targetProduct.price,
        product_measurements: null,
        product_name: targetProduct.name,
        selection_id: product.id, // = additional product selection id
        selection_type: 'additional_product_selection', // = morphable type
        selection_name: null,
        source: 'owner',
      })
    })

    this.createQuoteHelper(projectId, payload);
  }

  createQuoteHelper = async (projectId, payload) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const result = await api.projectMaterialquotesCreate(projectId, payload)
    if (result.error) { return this.setState({ error: result.error }); }
    else {
      window.location.reload(); //Window reload added due to apartment/submission form desync issues, 
    }
    
    await this.props.updateSubmissionFormCallback();
    const quote = await this.props.refreshQuoteCallback();
    this.setState({
      persons: quote.persons,
      additionalInfos: { infos: quote.rows}
    }); // TODO: find out where this should actually be called.


    await this.getQuoteRowsCallback(quote);
  };

  saveQuote = () => {
    const apartmentId = this.props.apartment.id;
    const submissionId = this.props.submissionForm.id;
    const projectId = this.props.quote.project_id;
    const formattedDate = this.state.deadline ? new Date(this.state.deadline.getTime() - (this.state.deadline.getTimezoneOffset() * 60000)) : '';

    const payload = {
      id: this.props.quote.id,
      apartment_id: apartmentId,
      material_form_submission_id: submissionId,
      name: this.state.name,
      additional_tasks: this.state.additionalTasks,
      additional_tasks_price: this.state.additionalTasksPrice || 0,
      rows: [],
      deadline: formattedDate,
      is_due: !!this.state.deadline,
      persons: [],
      additional_info: this.state.additionalQuoteInfo,
      completion_date: this.state.completionDate,
      removable_rows: this.state.removableRows,
    };

    this.state.persons.forEach((person) => {
      payload.persons.push({
        id: typeof person.id == "number" ? person.id : null,
        title: person.title,
        name: person.name,
        phone: person.phone,
      });
    });

    this.state.groups.forEach((group) => {
      group.selections.forEach((selection) => {
        const info = Object.values(this.state.additionalInfos.infos).find((x) => { if (x.id == selection.id) { return x.id == selection.id; } });
        payload.rows.push({
          id: selection.id,
          description: `${group.name}, ${selection.selection_name}, ${selection.product_name}`, // TODO: check if this is used anywhere
          price: selection.price,
          labor_price: selection.labor_price,
          package_amount: selection.package_amount,
          comment: selection.comment,
          pid: selection.product_id,
          additional_info: info ? info.additional_info : '',
          group_id: group.id,
          selection_id: selection.selection_id,
          selection_name: selection.selection_name,
          selection_type: selection.selection_type ?? 'material_selection', // = morphable type
          product_name: selection.product_name,
          product_measurements: selection.product_measurements,
          accepted_by_management: true,
          accepted_by_owner: true,
          hidden: selection.hidden
        });
      })
    });

    payload.name = this.state.name;

    this.setState({ loading: 1, newRows: [] }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);

      api.apartmentMaterialQuoteSave(projectId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        else {
          window.location.reload(); //Window reload added due to apartment/submission form desync issues, 
        }
        this.props.refreshQuoteCallback();
        this.getQuoteRowsCallback();
        this.setState({ loading: false });
      });
    });
  }

  publishQuote = () => {
    const apartmentId = this.props.apartment.id;
    const payload = this.props.quote;
    const projectId = this.props.quote.project_id;

    payload.name = this.state.name;
    payload.status = 'sent';
    payload.newQuote = true;

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.apartmentMaterialQuotePublish(projectId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        else {
          window.location.reload(); //Window reload added due to apartment/submission form desync issues, 
        }

        this.props.refreshQuoteCallback();
        this.setState({ loading: false });
      });
    });
  }

  updateQuote = (republish = false, skipQuote = false) => {
    const apartmentId = this.props.apartment.id;
    const submissionId = this.props.submissionForm.id;
    const projectId = this.props.quote.project_id;
    const formattedDate = this.state.deadline ? new Date(this.state.deadline.getTime() - (this.state.deadline.getTimezoneOffset() * 60000)) : '';

    const payload = {
      id: this.props.quote.id,
      apartment_id: apartmentId,
      material_form_submission_id: submissionId,
      name: this.state.name,
      rows: [],
      acceptedQuoteComments: [],
      additional_tasks: this.state.additionalTasks,
      additional_tasks_price: this.state.additionalTasksPrice || 0,
      deadline: formattedDate,
      persons: [],
      additional_info: this.state.additionalQuoteInfo,
      completion_date: this.state.completionDate,
      removable_rows: this.state.removableRows,
    };

    this.state.persons.forEach((person) => {
      payload.persons.push({
        id: typeof person.id == "number" ? person.id : null,
        title: person.title,
        name: person.name,
        phone: person.phone,
      });
    });

    this.state.groups.forEach((group) => {
      group.selections.forEach((selection) => {
        const info = Object.values(this.state.additionalInfos.infos).find((x) => { if (x.id == selection.id) { return x.id == selection.id; } });
        const quoteRowComments = this.props.submissionForm.material_selections.find((row) => row.id === selection.id) || null;

        payload.rows.push({
          additional_info: info ? info.additional_info : '',
          comment: selection.comment,
          description: `${group.name}, ${selection.selection_name}, ${selection.product_name}`, // TODO: check if this is used anywhere
          group_id: group.id,
          id: selection.id,
          labor_price: selection.labor_price,
          package_amount: selection.package_amount,
          pid: selection.product_id,
          price: selection.price,
          product_measurements: selection.product_measurements,
          product_name: selection.product_name,
          quote_row_comments: quoteRowComments,
          selection_id: selection.selection_id,
          selection_name: selection.selection_name,
          selection_type: selection.selection_type ?? 'material_selection', // = morphable type
          hidden: selection.hidden
        });
      })
    });

    payload.status = republish ? 'created' : 'sent';
    payload.skipped = 0;

    if(skipQuote){
      payload.status = 'approved';
      payload.skipped = 1;
    }

    payload.republish = republish;
    if(republish){
      payload.is_due = 1;
    }
    payload.acceptedQuoteComments = [];

    if (this.state.materialQuoteComments && this.state.materialQuoteComments.length > 0) {
      this.state.materialQuoteComments.forEach((comment) => {
        payload.acceptedQuoteComments.push(comment);
      });
    }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.apartmentMaterialQuoteSave(projectId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        this.props.refreshQuoteCallback();
        this.setState({ loading: false });
      });
    });
  }

  skipQuote = () => {
    window.confirm('Asiakkaalle ei lähetä tarjousta. Asiakas siirtyy "Odotetaan huonekorttia" tilaan. Asiakas ei saa tästä ilmoitusta.');
    this.updateQuote(false, true);
  }

  getTotalPriceForThisMaterialForm = () => {
    let total = 0;
    const { additionalRows } = this.state;

    this.state.groups.forEach(group => {
      group.selections.forEach(selection => {
        if(selection.hidden){
          return false;
        }
        total += Number(selection.price) * Number(group.name === 'Lisätuotteet' ? 1 : selection.package_amount);
        total += Number(selection.labor_price);
      })
    })

    total += Number(this.state.additionalTasksPrice);

    if (additionalRows && additionalRows.length > 0) {
      additionalRows.forEach((row) => {
        total += Number(row.price) + Number(row.labor_price);
      });
    }

    return Number(total).toFixed(2);
  }

  getLaborPriceForThisMaterialForm = () => {
    let total = 0;
    const { additionalRows } = this.state;

    this.state.groups.forEach(group => {
      group.selections.forEach(selection => {
        if(selection.hidden){
          return false;
        }

        total += Number(selection.labor_price);
      })
    })

    //total += Number(this.state.additionalTasksPrice);

    if (additionalRows && additionalRows.length > 0) {
      additionalRows.forEach((row) => {
        total += Number(row.labor_price);
      });
    }

    return Number(total).toFixed(2);
  }

  addComment = (event, selection, group) => {
    if (event.target.value !== '') {
      const api = new AvainiaCore(LocalStorageService.getToken);
      const payload = {
        material_quote_id: this.props.quote.id,
        material_form_submission_id: this.props.submissionForm.id,
        comment: event.target.value,
        row_id: parseInt(event.target.id, 10),
        selection_id: selection.id,
        comment_source: 'management',
        isPortal: false,
        accepted_by_management: true,
      };

      api.apartmentMaterialQuoteRowCommentsAdd(this.props.quote.id, payload)
        .then((comment) => {
          if (comment.error) { console.log(comment.error); return this.setState({ error: comment.error }); }

          let newSelection = group.selections.find(x => x.id === selection.id);
          newSelection.comments.push(payload);
          group.selections = group.selections.map(selection => {
            if (selection.id === newSelection.id ) {
              selection = newSelection;
            }
            return selection;
          });

          let newGroups = this.state.groups.map(newGroup => {
            if (newGroup.id === group.id) {
              newGroup = group;
            }
            return newGroup;
          })

          this.setState({
            newComment: '',
            key: Math.random(),
            groups: newGroups,
          });
        })
    }
  }

  addQuoteComment = (event) => {
    if (event.target.value !== '') {
      const api = new AvainiaCore(LocalStorageService.getToken);
      const comment = event.target.value;
      const payload = {
        material_quote_id: this.props.quote.id,
        material_form_submission_id: this.props.submissionForm.id,
        comment,
        source: 'management',
      };

      api.materialQuoteCommentAdd(this.props.quote.id, payload)
        .then((cmt) => {
          if (cmt.error) { return this.setState({ error: cmt.error }); }

          api.materialQuoteCommentsGet(this.props.quote.id).then((materialQuoteComments) => {
            this.setState({ newQuoteComment: '', materialQuoteComments });
          });
        });
    }
  }

  onChangeRowComment = (e, key) => {

    let val = e.target.value;
    let name = e.target.name;
    console.log(this.state.newComments);
    this.setState(prevState => ({
      newComments: {
          ...prevState.newComments,
          [key]: val
      },
      newComment: val,
    }))

  }

  onChangeComment = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  onChangeInfo = (e) => { this.setState({ additionalQuoteInfo: e.target.value }); }

  onChangeCompletionDate = (e) => { this.setState({ completionDate: e.target.value }); }

  handleSelectedFile = (e) => {
    const max = this.state.maxImages;
    const { files } = e.target;
    const currentFiles = this.state.selectedFiles ? this.state.selectedFiles.length : 0;
    const incomingFiles = files.length;
    const projectId = this.props.project.id;

    if (currentFiles + incomingFiles > max) {
      this.setState({ error: 25 });
    } else {
      this.setState({ error: false });

      var queueComplete = 0;
      var queueFiles = [];

      for (let i = 0; i < files.length; i++) {
        const photoPayload = {
          id: this.props.quote.id,
          image: files[i],
        };

        const api = new AvainiaCore(LocalStorageService.getToken);

        api.apartmentMaterialQuotePhotosSave(projectId, photoPayload).then((result) => {
          if (result.error) { return this.setState({ error: result.error }); }

            queueComplete++;
            queueFiles.push(result);

            if(queueComplete >= files.length){
              if(this.state.selectedFiles){
                this.setState((prevState) => ({
                  selectedFiles: [...prevState.selectedFiles, ...queueFiles],
                }));
              } else {
                this.setState((prevState) => ({
                  selectedFiles: [...queueFiles],
                }));
              } 
            }

        }).then(
          this.setState({
            key: Math.random(),
          }),
        );
      }
    }
  }

  removeSelectedFile = (index) => {
    const projectId = this.props.project.id;
    const selectedFile = this.state.selectedFiles.find((item, j) => index === j);
    const selectedFiles = this.state.selectedFiles.filter((item, j) => index !== j);
    const deletePhotoPayload = {
      quote_id: this.props.quote.id,
      photo_id: selectedFile.id,
      photo_path: selectedFile.photo_path,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.apartmentMaterialQuotePhotosDelete(projectId, deletePhotoPayload).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.setState((prevState) => ({
        selectedFiles,
        key: Math.random(),
      }));
    });
  }

  handleDateChange = (date) => {
    this.setState({
      deadline: date,
    });
  };

  addAdditionalRow = () => {
    const quoteId = this.props.quote.id;
    const { additionalRowTitle, additionalRowDescription, additionalRowPrice, additionalRowLaborPrice } = this.state;
    if (!additionalRowTitle || !additionalRowPrice || !additionalRowLaborPrice) { return; }

    const payload = {
      title: this.state.additionalRowTitle,
      description: this.state.additionalRowDescription,
      price: this.state.additionalRowPrice,
      laborPrice: this.state.additionalRowLaborPrice,
    };
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.materialQuoteAdditionalRowAdd(quoteId, payload).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.setState({
        additionalRowTitle: '',
        additionalRowDescription: '',
        additionalRowPrice: '',
        additionalRowLaborPrice: '',
      }, () => this.refreshQuoteAdditionalRows());
    });
  }

  refreshQuoteAdditionalRows = () => {
    const quoteId = this.props.quote.id;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.materialQuoteAdditionalRowsGet(quoteId).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.setState({
        additionalRows: result,
      });
    });
  }

  removeAdditionalRow = (rowId) => {
    const quoteId = this.props.quote.id;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.materialQuoteAdditionalRowsDelete(quoteId, rowId).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }
      this.refreshQuoteAdditionalRows();
    });
  }

  showComments = (id) => {
    let shownComments = this.state.openComments;
    const index = shownComments.indexOf(id);
    if (index > -1) {
      shownComments.splice(index, 1);
    } else {
      shownComments.push(id);
    }
    this.setState({ openComments: shownComments });
  }

  showAdditionalInfo = (id) => {
    let openAdditionalInfo = this.state.openAdditionalInfo;
    const index = openAdditionalInfo.indexOf(id);
    if (index > -1) {
      openAdditionalInfo.splice(index, 1);
    } else {
      openAdditionalInfo.push(id);
    }
    this.setState({ openAdditionalInfo: openAdditionalInfo });
  }

  editAdditionalRow = (row) => {
    this.setState({
      currentlyEditedRowId: row.id,
      additionalRowTitle: row.title,
      additionalRowDescription: row.description,
      additionalRowPrice: row.price,
      additionalRowLaborPrice: row.labor_price,
    });
  }

  removePerson = (id) => {
    var persons = this.state.persons;
    persons = persons.filter((x) => x.id !== id);
    this.setState({persons});
  }

  editPerson = (id) => {
    let person = this.state.persons.filter((x) => x.id == id)[0];
    let p1 = {
      ...person
    };
    this.setState({
      person_edited: p1,
    });
  }

  saveEditPerson = () => {
    let id = this.state.person_edited.id;
    var persons = this.state.persons;

    if(id){
      persons = persons.filter((x) => x.id !== id);
      persons.push(this.state.person_edited);
    } else {
      let new_person = this.state.person_edited;
      new_person.id = "temp_" + Math.random();
      new_person.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
      console.log(new_person);
      persons.push(new_person);
    }

    this.setState({persons: persons, person_edited: { id: null,title: "",name: "",phone: "",}});

  }

  personEditedOnChange = (e) => {
    let person_edited = this.state.person_edited;
    person_edited[e.target.name] = e.target.value;
    this.setState({ person_edited });
  }

  saveEditedAdditionalRow = () => {
    const quoteId = this.props.quote.id;
    const rowId = this.state.currentlyEditedRowId;

    const payload = {
      title: this.state.additionalRowTitle,
      description: this.state.additionalRowDescription,
      price: this.state.additionalRowPrice,
      laborPrice: this.state.additionalRowLaborPrice,
    };
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.materialQuoteAdditionalRowEdit(quoteId, rowId, payload).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.setState({
        additionalRowTitle: '',
        additionalRowDescription: '',
        additionalRowPrice: '',
        additionalRowLaborPrice: '',
        currentlyEditedRowId: null,
      }, () => this.refreshQuoteAdditionalRows());
    });
  }

  editQuoteRow = (groupId, rowId) => {
    const group = this.state.groups.find(group => group.id === groupId);
    const row = group.selections.find((selection) => selection.id == rowId);

    this.setState({
      selectionRowEdited: {
        id: row.id,
        groupId: group.id,
        productId: row.product_id,
        selectionName: row.selection_name,
        productName: row.product_name,
        price: row.price,
        laborPrice: row.labor_price,
        measurements: row.product_measurements,
        packageAmount: row.package_amount,
      },
    });
  }

  removeSelectionRow = (groupId, rowId) => {
    if (!window.confirm(I18n.t('views.materialforms.accept-quote-row-delete'))) {
      return;
    }

    let newGroups = this.state.groups;
    const newSelections = newGroups.find(group => group.id === groupId).selections.filter((sel) => sel.id !== rowId);

    newGroups = newGroups.map(group => {
      if (group.id === groupId) {
        group.selections = newSelections;
      }
      return group;
    })

    this.setState(prevstate => ({
      groups: newGroups,
      removableRows: [
        ...prevstate.removableRows,
        rowId,
      ]
    }));
  }

  hideSelectionRow = (groupId, rowId) => {
    let newGroups = this.state.groups;
    let selections = newGroups.find(group => group.id === groupId).selections;

    selections = selections.map(sel => {
      if (sel.id === rowId) {
        sel.hidden = !sel.hidden;
      }
    })

    this.setState(prevstate => ({
      groups: newGroups,
    }));
  }

  addSelectionRow = (groupId, isAdditionalProduct) => {
    let newGroups = [...this.state.groups];
    let newGroup = newGroups.find(group => group.id === groupId);
    let newSelections = newGroup.selections;
    let selectionId = this.state.lastSelectionId + 1;

    if (isAdditionalProduct) {
      newSelections.push({
        id: selectionId,
        comments: [],
        description: '',
        price: 0,
        labor_price: null,
        package_amount: null,
        product_id: null,
        selection_id: null,
        selection_name: '',
        selection_type: 'additional_product_selection',
        product_name: '',
        product_measurements: null,
      });
    } else {
      newSelections.push({
        id: selectionId,
        comments: [],
        price: 0,
        labor_price: 0,
        package_amount: 0,
        product_id: null,
        selection_id: null,
        selection_name: '',
        selection_type: 'material_selection',
        product_name: '',
        product_measurements: '',
      });
    }

    newGroups = newGroups.map(group => {
      if (group.id === groupId) {
        group.selections = newSelections;
      }
      return group;
    });

    let newAdditionalInfos = {...this.state.additionalInfos};

    // Try to guess what new additional info id should be
    const infoId = newAdditionalInfos.infos[newAdditionalInfos.infos?.length -1]?.id ? (newAdditionalInfos.infos[newAdditionalInfos.infos.length -1]?.id + 1) : 1;

    newAdditionalInfos.infos.push({
      additional_info: '',
      comments: [],
      id: selectionId,
      selection_id: null,
      selection_name: '',
    })

    let newNewRows = this.state.newRows;
    newNewRows.push(selectionId);

    this.setState({
      additionalInfos: newAdditionalInfos,
      groups: newGroups,
      selectionRowEdited: {
        id: selectionId,
        groupId: newGroup.id,
        productId: null,
        selectionName: '',
        productName: '',
        price: 0,
        laborPrice: 0,
        measurements: '',
        packageAmount: 0,
        deleteOnCancel: true,
      },
      lastSelectionId: selectionId,
      newRows: newNewRows,
    })
  }

  onEdited = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let customProduct = false;

    if (['productName', 'measurements'].includes(name)) {
      customProduct = true;
    }

    this.setState(prevState => ({
      selectionRowEdited: {
      ...prevState.selectionRowEdited,
      [name]: value,
      productId: customProduct ? null : prevState.selectionRowEdited.productId, // Can this be done better?
      }
    }));
  }

  onSelect = (productId, isAdditionalProduct = false) => {
    const product = isAdditionalProduct ? this.state.additionalProducts.filter((product => product.id === productId)) : this.state.products.filter((product => product.id === productId));

    this.setState(prevState => ({
      selectionRowEdited: {
        ...prevState.selectionRowEdited,
        productId,
        productName: product[0].name,
        price: product[0].price,
        labor_price: 0,
        measurements: product[0].measurements,
        },
      })
    );
  }

  onSearch = (e, isAdditionalProduct = false) => {
    const searchWord = e?.target?.value ?? null;
    const identifier = isAdditionalProduct ? 'filteredAdditionalProducts' : 'filteredProducts';
    const defaultProducts = isAdditionalProduct ? this.state.additionalProducts : this.state.products;

    this.setState({ productSearch: searchWord });

    if (searchWord.length >= 3) {
      console.log("test");
      const products = isAdditionalProduct ? [...this.state.additionalProducts] : [...this.state.products];
      console.log(products);
      const regexp = new RegExp(searchWord, 'gi');
      const filtered = products.filter(product => regexp.test(product.name) || regexp.test(product.code) );
      console.log(filtered);

      this.setState({
        [identifier]: filtered,
      })
    } else {
      this.setState({
        [identifier]: defaultProducts,
      })
    }
  }

  saveSelection = (selectId, groupId) => {
    let newGroups = [...this.state.groups];
    let newGroup = this.state.groups.find(group => group.id === groupId);
    let newSelection = newGroup.selections.find(sel => sel.id === selectId);

    newSelection = {
      ...newSelection,
      selection_name: this.state.selectionRowEdited.selectionName,
      labor_price: this.state.selectionRowEdited.laborPrice,
      package_amount: this.state.selectionRowEdited.packageAmount,
      measurements: this.state.selectionRowEdited.measurements,
      product_name: this.state.selectionRowEdited.productName,
      price: this.state.selectionRowEdited.price,
      product_id: this.state.selectionRowEdited.productId,
      product_measurements: this.state.selectionRowEdited.measurements,
    }

    newGroup.selections = newGroup.selections.map(selection => {
      if (selection.id === newSelection.id) {
        selection = newSelection;
      }
      return selection;
    })

    newGroups = newGroups.map(group => {
      if (group.id === newGroup.id) {
        group = newGroup;
      }
      return group;
    })

    this.setState({
      groups: newGroups,
      selectionRowEdited: null,
    });
  }

  cancelEdit = (groupId, selectId) => {
    // Removes just added new row
    if (this.state.selectionRowEdited.deleteOnCancel === true) {
      this.removeSelectionRow(groupId, selectId);
    }
    this.setState({selectionRowEdited: null});
  }

  republishQuote = () => {
    window.confirm('Tarjouksen avaaminen palauttaa asiakkaan portaalissa "Odottaa tarjousta" tilaan. \n \n Asiakas saa ilmoituksen vasta kun lähetät tarjouksen uudelleen.');
    this.updateQuote(true);
  }

  calcRowPrice = (laborPrice, price, packageAmount, groupName) => {
    let materials = 0;

    if(groupName === 'Lisätuotteet'){
      materials = parseFloat(price) * 1;
    } else {
      materials = parseFloat(price) * parseFloat(packageAmount);
    }

    let total = parseFloat(laborPrice) + materials;
    return total.toFixed(2);
  }

  render() {
    const { quote, submissionForm, apartment } = this.props;
    const targetMaterialForm = this.props.materialforms.find((materialform) => materialform.id === this.props.targetMaterialForm.id);
    const groups = this.state.groups ?? [];
    const apartmentName = `${apartment.apartment_number} ${apartment.stairwell}`;

    var persons_ordered = this.state.persons.sort(function(a, b) {
      if(!a.created_at && b.created_at){ return 1;}
      if(a.created_at && !b.created_at){ return -1;}
      return a.created_at > b.created_at ? 1 : -1;
    });

    let idError = false;
    if(this.props.apartment.id && this.props.submissionForm.apartment_id && this.props.apartment.id != this.props.submissionForm.apartment_id){
      idError = true;
    }

    return (
      this.state.groups &&
      <Modal show={ true } onHide={ this.props.onHide } dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>{ I18n.t('views.materialforms.apartment-materialform-quote') }</Modal.Title>
        </Modal.Header>

        {idError &&
          <Badge variant="warning">Huom: Asunto ja materiaalivalinnat eivät täsmää!</Badge>
        }

        {this.props.quote && <Modal.Body>
          { this.state.error && <Error inline error={ this.state.error } /> }
          { !this.state.error && this.state.loading && <Loading inline /> }
          { !this.state.error && !this.state.loading && <>
            { quote && <p><Badge variant="info">{ I18n.t(`views.materialforms.quote-status-${quote.status}`) }</Badge></p> }
            { !quote && <p><Badge variant="info">{ I18n.t('views.materialforms.quote-status-unsaved') }</Badge></p> }

            <div style={{display:"flex"}}>
              <div style={{width: "30%"}}>
                <b>{ I18n.t('views.materialforms.quote-apartment') }</b>
                <Form.Control disabled name="apartment" value={ apartmentName } type="text"></Form.Control>
                <b>{ I18n.t('views.materialforms.quote-name') }</b>
                <Form.Control
                  name="name"
                  value={ this.state.name }
                  type="text"
                  onChange={ this.onChange }
                  disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                />
                <b>{ I18n.t('views.materialforms.deadline') }</b>
                <Form.Group>
                  <DatePicker
                    placeholderText="deadline"
                    className="form-control"
                    todayButton={ I18n.t('general.today') }
                    selected={ this.state.deadline }
                    onChange={ (newDate) => this.handleDateChange(newDate) }
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={ 30 }
                    disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                  />
                </Form.Group>
              </div>

              <div style={{width:"70%", paddingLeft: "15px", display: "flex", alignContent: "space-between", flexWrap: "wrap"}}>
                <div style={{width:"100%"}}>
                  <div><b>Henkilötiedot</b></div>
                  {persons_ordered.map((row, index) =>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <div>
                        <b>#{index + 1}</b>
                      </div>
                      <div style={{paddingLeft: "15px"}}>
                        <p style={{marginBottom:0}}>{row.title} {row.name}</p>
                        <span>{row.phone}</span>
                      </div>
                      <div style={{paddingLeft: "15px"}}>
                        <div style={{cursor:"pointer"}} onClick={() => { this.removePerson(row.id); }} variant="secondary"><Trash/></div>
                      </div>
                      <div style={{paddingLeft: "15px"}}>
                        <div style={{cursor:"pointer"}} onClick={() => { this.editPerson(row.id); }} variant="secondary"><Edit/></div>
                      </div>
                    </div>
                  )}
                </div>

                <div style={{width:"100%", paddingTop:"30px"}}>
                  <div> {this.state.person_edited.id ? <b> Muokkaa henkilöä </b> : <b> Lisää henkilö </b>} </div>
                  <div style={{display:"flex"}}>
                    <Form.Control placeholder="Titteli" name="title" value={ this.state.person_edited.title } type="text" onChange={ this.personEditedOnChange }/>
                    <Form.Control style={{marginLeft: "15px"}} placeholder="Nimi" name="name" value={ this.state.person_edited.name } type="text" onChange={ this.personEditedOnChange }/>
                    <Form.Control style={{marginLeft: "15px"}} placeholder="Puhelinnumero" name="phone" value={ this.state.person_edited.phone } type="text" onChange={ this.personEditedOnChange }/>
                    <Button style={{marginLeft: "15px"}} onClick={this.saveEditPerson} variant="secondary">Tallenna</Button>
                  </div>
                </div>
              </div>

            </div>

            <hr/>

            <div style={{display:"flex"}}>
              <div style={{width:"50%"}}>
                <div><b>Yleiset tiedot</b></div>
                <div>Asutaanko remontin aikana: { submissionForm.residents_stay_during_repairs ? 'Kyllä' : 'Ei' }</div>
                <div>Aikaisemmat remontit: { submissionForm.info_background } </div>
                <div>Säilytettäviä kalusteita: { submissionForm.info_keep && submissionForm.info_keep ? 'Kyllä' : 'Ei' } </div>
                <div>Mitä säilytetään: { submissionForm.info_keep_store ? submissionForm.info_keep_store : '-' } </div>
                <div>Mitä asennetaan takaisin: { submissionForm.info_keep_reinstall ? submissionForm.info_keep_reinstall : '-' } </div>
                <div>{ I18n.t('fields.info') }: { submissionForm.info_free_word ?? '-' } </div>
                <br />
              </div>
              <div style={{width:"50%"}}>
              <Button onClick={() => { this.showComments("commoncomments"); }} variant="secondary">
                { I18n.t('views.materialforms.answer-owner') } { this.state.materialQuoteComments && this.state.materialQuoteComments.length > 0 && <span>({this.state.materialQuoteComments.length})</span> }
              </Button>

                {this.state.openComments.includes("commoncomments") &&
                  <table style={ { width: '100%' } }>
                    { submissionForm.status === 'sent' && quote && <>
                      { this.state.materialQuoteComments && this.state.materialQuoteComments.length > 0 && <th>
                        Kommentit
                      </th> }
                      { this.state.materialQuoteComments && this.state.materialQuoteComments.length !== 0 && this.state.materialQuoteComments.map((comment) => <>
                        <tr className="offerComments" style={ { fontStyle: 'italic', fontSize: '14px' } }>
                          <td>{ comment.created_at } { comment.user.name } : { comment.comment }</td>
                        </tr>
                      </>) }
                      { quote && quote.status !== 'approved' && quote.status !== 'sent' &&
                        <tr>
                          <Form.Control
                            type="text"
                            class="comment-textarea"
                            name="newQuoteComment"
                            value={ this.state.newQuoteComment }
                            onKeyUp={ (event) => (event.keyCode === 13 ? this.addQuoteComment(event) : null) }
                            onChange={ this.onChangeComment }
                            placeholder={ I18n.t('views.materialforms.add-comment') }
                            style={ { margin: 'auto' } }
                          />
                        </tr>
                      }
                    </> }
                  </table>
                }
              </div>
            </div>
            <hr/>
            <div>
              <div style={{paddingBottom:"30px"}}>
                <b>{ I18n.t('views.materialforms.additional-rows') }</b>
              </div>

              <Button variant="primary" style={{ width:"max-content" }} onClick={() => { this.setState({showAdditionalRowAdd : !this.state.showAdditionalRowAdd }) }}> Lisää uusi lisärivi </Button>

              { this.state.showAdditionalRowAdd &&
              <div style={{paddingLeft:"30px", borderLeft: "4px solid #cccccc"}}>
                { quote && quote.status !== 'approved' && quote.status !== 'sent' &&
                  <table style={ { width: '100%', maxWidth: '500px' } }>
                    <th>{ I18n.t('views.materialforms.new-additional-row') }</th>
                    <tr>
                      <div>
                        <Form.Control
                          className="additional-row-title"
                          name="additionalRowTitle"
                          value={ this.state.additionalRowTitle }
                          onChange={ this.onChange }
                          style={ { width:"100%" } }
                          placeholder="Nimike"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                      </div>
                      <div>
                        <Form.Control
                          className="additional-row-description"
                          as="textarea"
                          name="additionalRowDescription"
                          value={ this.state.additionalRowDescription }
                          onChange={ this.onChange }
                          style={ { width:"100%" } }
                          placeholder="Kuvaus"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                      </div>
                      <div style={ { display: 'flex' } }>
                        <Form.Control
                          type="number"
                          name="additionalRowPrice"
                          className="additional-row-price"
                          value={ this.state.additionalRowPrice }
                          onChange={ this.onChange }
                          style={ { width:"50%" } }
                          placeholder="Hinta"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                        <Form.Control
                          type="number"
                          name="additionalRowLaborPrice"
                          className="additional-row-labor-price"
                          value={ this.state.additionalRowLaborPrice }
                          onChange={ this.onChange }
                          style={ { width:"50%" } }
                          placeholder="Työn hinta"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                      </div>
                      <div style={{paddingTop:"15px", paddingBottom:"30px"}}>
                        <Button variant="success" className="additional-row-button" onClick={ this.addAdditionalRow }>{ I18n.t('general.save') }</Button>
                      </div>
                    </tr>
                  </table>
                }
              </div> }

              <div>
                { quote && this.state.additionalRows && <>
                  <Row className="additional-rows-heading">
                    <Col xs={ 2 }>Nimi *</Col>
                    <Col xs={ 5 }>Kuvaus *</Col>
                    <Col xs={ 1 }>Hinta *</Col>
                    <Col xs={ 1 }>Työn hinta *</Col>
                    <Col xs={ 1 }>Yhteensä</Col>
                  </Row>
                </>
                }
                { quote && this.state.additionalRows &&
                  this.state.additionalRows.map((row) => {
                    return (
                      <Row>
                        <Col xs={ 2 }>{ row.title }</Col>
                        <Col xs={ 5 }>{ row.description }</Col>
                        <Col xs={ 1 }>{ row.price }</Col>
                        <Col xs={ 1 }>{ row.labor_price }</Col>
                        <Col xs={ 1 }>{ parseFloat(parseFloat(row.price) + parseFloat(row.labor_price)).toFixed(2) }</Col>
                        <Col xs={ 2 }>
                          { quote && quote.status !== 'approved' && quote.status !== 'sent' && <>
                            <Trash onClick={ () => { this.removeAdditionalRow(row.id); } } className="clickable" />
                          </> }
                        </Col>
                      </Row>
                    );
                  })
                }
              </div>
            </div>
            <hr/>
            { groups && groups.map((group, groupIndex) => { return (
              <table mt-2 style={ { width: '100%', tableLayout: 'fixed' } }>
              {/* Normal groups */}
              { group.name !== 'Lisätuotteet' &&
              <thead>
                <th colspan="14">{ group.name }</th>
                <tr>
                  <th colspan="2">Valinnan nimi</th>
                  <th colspan="3">Tuotteen nimi</th>
                  <th colspan="1">Mittatiedot</th>
                  <th colspan="1">Määrä</th>
                  <th colspan="2">Materiaali hinta</th>
                  <th colspan="1">{ I18n.t('views.products.labor_price') }</th>
                  <th colspan="1">{ I18n.t('views.products.total') }</th>
                  <th colspan="4"></th>
                  { submissionForm.status === 'open' && <th>{ I18n.t('views.materialforms.comment') }</th> }
                </tr>
              </thead>}
              {/* Additional products */}
              { group.name === 'Lisätuotteet' &&
              <thead>
                <th colspan="14">{ group.name }</th>
                <tr>
                  <th colspan="2">Tuotteen nimi</th>
                  <th colspan="2">Tuotteen hinta</th>
                  <th colspan="1">{ I18n.t('views.products.labor_price') }</th>
                  <th colspan="1">{ I18n.t('views.products.total') }</th>
                  <th colspan="4"></th>
                  <th colspan="4"></th>
                  { submissionForm.status === 'open' && <th>{ I18n.t('views.materialforms.comment') }</th> }
                </tr>
              </thead>}
              <tbody>
                { group.selections && group.selections.map((select, selectIndex) => <>
                  {/* Row is selected */}
                  { this.state.selectionRowEdited?.id === select.id && ( quote.status !== 'sent' || quote.status === 'approved') &&
                    <tr>
                      {group.name !== 'Lisätuotteet' && <td colspan="2">
                          <Form.Control
                            className="selection-name"
                            name="selectionName"
                            value={ this.state.selectionRowEdited?.selectionName }
                            onChange={ this.onEdited }
                            style={ { width:"100%" } }
                            placeholder="Valinnan nimi"
                            disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                          />
                      </td>}
                      <td colspan="3">
                        <Dropdown as={ButtonGroup}>
                          <Form.Control
                            className="select-name"
                            name="productName"
                            value={ this.state.selectionRowEdited?.productName }
                            onChange={ this.onEdited }
                            style={ { width:"100%" } }
                            placeholder="Nimi"
                            disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                          />
                          <Dropdown.Toggle split variant="success" className='product-select-btn' />
                          <Dropdown.Menu>
                            <Dropdown.Divider />
                            <Form.Control
                              className="product-search"
                              name="productSearch"
                              value={ this.state.productSearch }
                              onChange={ (e) => this.onSearch(e, group.name === 'Lisätuotteet' ? true : false) }
                              style={ { width:"100%" } }
                              placeholder="Hae tuotetta nimellä"
                              disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                            />
                            {group.name !== 'Lisätuotteet' && this.state.filteredProducts.map((product, productIndex) => {
                              return <Dropdown.Item eventKey={`product-${productIndex}`} onSelect={() => this.onSelect(product.id) } active={ product.id === select.product?.id ? true : false} >
                                  { product.code != 'NULL' && product.code ? product.code + ' -' : '' } {product.name}
                                </Dropdown.Item>
                            })}
                            {group.name === 'Lisätuotteet' && this.state.filteredAdditionalProducts.map((product, productIndex) => {
                              return <Dropdown.Item eventKey={`product-${productIndex}`} onSelect={() => this.onSelect(product.id, true) } active={ product.id === select.product?.id ? true : false} >
                                  { product.code != 'NULL' && product.code ? product.code + ' -' : '' } {product.name}
                                </Dropdown.Item>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      { group.name !== 'Lisätuotteet' && <td colspan="1">
                        <Form.Control
                          className="select-measurements"
                          name="measurements"
                          value={ this.state.selectionRowEdited?.measurements }
                          onChange={ this.onEdited }
                          style={ { width:"100%" } }
                          placeholder="Mitat"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                      </td>}
                      {group.name !== 'Lisätuotteet' && <td colspan="1">
                        <Form.Control
                          className="select-package-size"
                          name="packageAmount"
                          value={ this.state.selectionRowEdited?.packageAmount }
                          onChange={ this.onEdited }
                          style={ { width:"100%" } }
                          placeholder="Pakkauskoko"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                      </td>}
                      <td colspan="2">
                        <Form.Control
                          className="select-price"
                          name="price"
                          value={ this.state.selectionRowEdited?.price }
                          onChange={ this.onEdited }
                          style={ { width:"100%" } }
                          placeholder="Hinta"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                      </td>
                      <td colspan="1">
                        <Form.Control
                          className="select-labor-price"
                          name="laborPrice"
                          value={ this.state.selectionRowEdited?.laborPrice }
                          onChange={ this.onEdited }
                          style={ { width:"100%" } }
                          placeholder="Hinta"
                          disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
                        />
                      </td>
                      <td colspan="1">{ this.calcRowPrice(this.state.selectionRowEdited?.laborPrice, this.state.selectionRowEdited?.price, this.state.selectionRowEdited?.packageAmount, group.name) }€</td>

                      { group.name === 'Lisätuotteet' && <td colspan="4"></td> }
                      <td colspan="3">
                        <Button onClick={() => this.saveSelection(select.id, group.id)} variant="primary">
                          Päivitä
                        </Button>
                        <Button variant="secondary" style={{marginRight: "15px"}} onClick={() =>  this.cancelEdit(group.id, select.id) }>
                          { I18n.t('views.materialforms.button-cancel') }
                        </Button>
                      </td>
                    </tr>
                  }
                  {/* Row is not selected */}
                  { this.state.selectionRowEdited?.id !== select.id && <>
                    <tr style={select.hidden ? {color: "#ad222f"} : {}} title={ select.description }>
                    { group.name !== 'Lisätuotteet' && <td colspan="2">
                      { select.selection_name }
                    </td>}
                    <td colspan="3">
                      { select.product_name }
                      { select.default && <i>&nbsp; { I18n.t('views.materialforms.default-option') }</i> }
                    </td>
                    { group.name !== 'Lisätuotteet' && <td colspan="1">{ select.product_measurements }</td>}
                    { group.name !== 'Lisätuotteet' && <td colspan="1">{ select.package_amount  }</td>}
                    <td colspan="2">{ select.price } { select.package_amount ? <>x { select.package_amount } Yht. { (select.price * select.package_amount).toFixed(2) } </> : '' }€</td>
                    <td colspan="1">{ select.labor_price }€</td>
                    <td colspan="1">{this.calcRowPrice(select.labor_price, select.price, select.package_amount, group.name)}€</td>
                    { group.name === 'Lisätuotteet' && <td colspan="4"/>}
                    <td colspan="3">
                    {quote && quote.status !== 'approved' && quote.status !== 'sent' &&
                      <span>
                        {select.hidden == 0 && <Close style={{padding:"2px"}} onClick={ () => { this.hideSelectionRow(group.id, select.id); } } className="clickable" />}
                        {select.hidden == 1 && <Check style={{padding:"2px"}} onClick={ () => { this.hideSelectionRow(group.id, select.id); } } className="clickable" />}
                        <Edit onClick={() => { this.editQuoteRow(group.id, select.id); }} className="clickable" />
                        <Trash onClick={ () => { this.removeSelectionRow(group.id, select.id); } } className="clickable" />
                      </span>}
                      <Button style={{marginRight: "15px"}} onClick={() => { this.showAdditionalInfo(select.id); }} variant={this.state.additionalInfos.infos.find((row) => row.id === select.id)?.additional_info ? 'primary' : 'secondary'}>
                        Lisätiedot
                      </Button>
                      <Button onClick={() => { this.showComments(select.id); }} variant={select.comments.length > 0 ? 'primary' : 'secondary'} disabled={this.state.newRows.find(row => row === select.id) ? true : false}>
                        { I18n.t('views.materialforms.answer-owner') } { select.comments && <span>({select.comments.length})</span> }
                      </Button>
                    </td>
                  </tr> 
                  </>
                  }
                  {this.state.openComments.includes(select.id) && <td colspan="7">
                    { submissionForm.status === 'sent' && select.comments && select.comments.map((comment) => <div className="offerComments" style={ { fontStyle: 'italic', fontSize: '14px' } }>
                      <div>{ comment?.created_at ?? 'Juuri nyt,' } { comment?.user?.name ?? ' sinä' } : { comment?.comment }</div>
                    </div>) }
                    { submissionForm.status === 'sent' && quote && <>
                      { quote && quote.status !== 'approved' && quote.status !== 'sent' &&
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            type="text"
                            name={ select.id }
                            id={ select.id }
                            value={ this.state.newComments[select.id] }
                            onKeyUp={ (event) => (event.keyCode === 13 ? this.addComment(event, select, group) : null) }
                            onChange={(e) => this.onChangeRowComment(e, "comment-" + select.id)}
                            placeholder={ I18n.t('views.materialforms.add-comment') }
                            style={ { margin: 'auto' } }
                          />
                        </Form.Group>
                      }
                    </> }
                  </td> }

                  {this.state.openAdditionalInfo.includes(select.id) && <>
                    <td colspan="7">
                      <Form.Control
                        as="textarea"
                        name={ select.id }
                        value={ this.state.additionalInfos.infos ? this.state.additionalInfos.infos.find((row) => row.selection_id === select.selection_id)?.additional_info : '' }
                        type="text"
                        onChange={ this.onChangeComments }
                        placeholder={ I18n.t('views.materialforms.additionalinfo-placeholder') }
                        >
                      </Form.Control>
                    </td>
                  </>}
                </>) }
                { !this.state.selectionRowEdited && quote && quote.status !== 'approved' && quote.status !== 'sent' &&
                <tr style={{ paddingTop:"15px", paddingBottom:"30px" }}>
                  <Button variant="success" style={{ width:"max-content" }} className="additional-selection-button" onClick={ () => this.addSelectionRow(group.id, group.name === 'Lisätuotteet' ? true : false) }>Lisää uusi rivi</Button>
                </tr>}
                { !groups && <> <tr>-</tr> </> }
                <tr>&nbsp;</tr>
              </tbody>
            </table>
            )})}

          <div style={{ textAlign: 'right', margin:'1rem 12rem 0rem 0rem' }}>
            <b style={ { textDecoration: 'underline' } }>
            { I18n.t('views.products.labor_price') }: { this.getLaborPriceForThisMaterialForm() }
            </b>
          </div>
          <div style={{ textAlign: 'right', margin:'0rem 12rem 1rem 0rem' }}>
            <b style={ { textDecoration: 'underline' } }>
              { I18n.t('general.total-price') }: { this.getTotalPriceForThisMaterialForm() }
            </b>
          </div>
            <hr/>
            <div style={{ width:"100%" }}>
              <b>{ I18n.t('views.materialforms.completion-date') }</b>
            </div>
            <Form.Control
              className="additional-quote-info"
              name="completion-date"
              value={ this.state.completionDate }
              onChange={ this.onChangeCompletionDate }
              style={ { width:"30%" } }
              placeholder="Pvm."
              disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
            />

            <div style={{ width:"100%" }}>
              <b>{ I18n.t('views.materialforms.additional-quote-info') }</b>
            </div>
            <Form.Control
              className="additional-quote-info"
              as="textarea"
              name="additional-quote-info"
              value={ this.state.additionalQuoteInfo }
              onChange={ this.onChangeInfo }
              style={ { width:"100%" } }
              placeholder="Tarkennus"
              disabled={ quote ? quote.status === 'sent' || quote.status === 'approved' : false }
            />

            <hr/>
            <div>
              <b>Tarjouksen liitteet</b>
              <br/><br/>
              { quote && (quote.status !== 'sent' && quote.status !== 'approved') &&
                <div>
                  <div style={{display:"flex", alignItems: "center"}}>
                    <input style={ { display: 'none' } } type="file" id="files" multiple name="addNewImage" onChange={ this.handleSelectedFile } key={ Math.random() } />
                    <label style={ { marginRight: '20px' } } className="label-select-files" htmlFor="files">{ I18n.t('views.infraproject.select-files') }</label>
                    <p>{ I18n.t('views.infraproject.max-files') }{ this.state.maxImages }</p>
                  </div>
                  <div style={{paddingLeft: "30px"}}>
                    { this.state.selectedFiles && this.state.selectedFiles.map((file, index) => <p key={ index }><b>Liite {index + 1} : { file.name }</b> <button onClick={ () => this.removeSelectedFile(index) }>{ I18n.t('general.delete') }</button></p>) }
                  </div>
                </div>
              }
            </div>

          </> }
        </Modal.Body>}
        <Modal.Footer>
          { !quote && <Button variant="primary" onClick={ this.createQuote }>{ I18n.t('views.materialforms.button-create-quote') }</Button> }
          { quote && (quote.status === 'created' || quote.status === 'pending') && <Button variant="primary" onClick={ this.saveQuote }>{ I18n.t('views.materialforms.button-save-quote') }</Button> }
          { quote && quote.status === 'created' && <Button variant="success" onClick={ this.publishQuote }>{ I18n.t('views.materialforms.button-publish-quote') }</Button> }
          { quote && (quote.status === 'approved' || quote.status === 'sent') && <Button variant="secondary" onClick={this.republishQuote}>Avaa tarjous muokattavaksi</Button> }
          { quote && quote.status === 'pending' && <Button variant="secondary" onClick={ this.updateQuote }>Hyväksy muutokset</Button> }
          { quote && quote.status === 'created' && <Button variant="secondary" onClick={ this.skipQuote }> Siirry huonekortti vaiheeseen </Button> }
          {/* Jos ei uusia kommentteja niin piilota updateQuote ja lisää finalizeQuote */ }
          <Button variant="secondary" onClick={ this.props.onHide }>{ I18n.t('views.materialforms.button-cancel') }</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ApartmentMaterialselectionQuoteModal;
